<script setup lang="ts">
import {
  getSmallestThumbnailUrl,
  getProductRoute,
} from '@shopware-pwa/helpers-next';
import type { LineItem } from '@shopware-pwa/types';
import { useGtmTrackingWithProduct } from '~/composables/useGTMTracking';

const props = defineProps<{
  cartItem: LineItem;
  maxQty?: number;
}>();

const { cartItem } = toRefs(props);

const isLoading = ref(false);
const { codeErrorsNotification } = useCartNotification();

const {
  itemOptions,
  removeItem,
  itemTotalPrice,
  itemQuantity,
  isPromotion,
  changeItemQuantity,
} = useCartItem(cartItem);

const quantity = ref();
syncRefs(itemQuantity, quantity);

const updateQuantity = async (quantity: number | undefined) => {
  if (quantity === itemQuantity.value) return;

  isLoading.value = true;

  await changeItemQuantity(Number(quantity));
  codeErrorsNotification();

  isLoading.value = false;
};
const debounceUpdate = useDebounceFn(updateQuantity, 800);

watch(quantity, () => debounceUpdate(quantity.value));

const removeCartItem = async () => {
  isLoading.value = true;
  await removeItem();
  isLoading.value = false;
  useGtmTrackingWithProduct(
    cartItem.value.price?.totalPrice,
    'remove_from_cart',
    [cartItem.value],
    { ecomm_pagetype: 'basket' },
  );
};

const colorOption = itemOptions.value.find(
  (option) => option.group === 'Farbe',
);
const sizeOption = itemOptions.value.find((option) => option.group === 'Größe');
</script>

<template>
  <div class="flex w-full items-center gap-3">
    <RouterLink
      v-if="!isPromotion"
      :to="getProductRoute({ id: cartItem.payload.parentId })"
      class="w-25 h-25 col-span-2 aspect-square object-cover object-top md:h-36 md:w-36"
    >
      <NuxtImg
        :src="getSmallestThumbnailUrl(cartItem.cover) || cartItem.cover?.url"
        :alt="`${cartItem.label || cartItem.payload.name || ''} cart item`"
        class="w-25 h-25 col-span-2 aspect-square object-cover object-top md:h-36 md:w-36"
      />
    </RouterLink>
    <div class="flex w-full flex-col items-start justify-start gap-2">
      <div class="flex w-full justify-between">
        <RouterLink :to="getProductRoute({ id: cartItem.payload.parentId })">
          <h3 class="text-left text-xl font-bold">
            {{ cartItem.label }}
            <span
              v-if="isPromotion"
              class="mr-2 rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900 dark:text-green-300"
              >Promotion</span
            >
          </h3>
        </RouterLink>
        <SharedPrice :value="itemTotalPrice" />
      </div>
      <p class="text-left text-gray-500">
        {{ colorOption?.group }}
        <span class="px-2 font-bold text-black">
          {{ colorOption?.option }}
        </span>
      </p>
      <div class="flex w-full justify-between">
        <p class="text-gray-500">
          {{ sizeOption?.group }}
          <span class="px-2 font-bold text-black">
            {{ sizeOption?.option }}
          </span>
        </p>
        <SharedSpecialSelect
          v-if="!isPromotion"
          v-model="quantity"
          class="cols-span-1 ![&>label]:text-base justify-self-center font-bold md:self-start"
          name="quantity"
          label="Anzahl"
          is-optional
          hide-please-select
          :options="
            [
              ...Array(
                cartItem.quantityInformation?.maxPurchase <= 10
                  ? cartItem.quantityInformation?.maxPurchase
                  : 10,
              ).keys(),
            ].map((index) => index + 1)
          "
        />
        <button
          v-if="!isPromotion"
          type="button"
          class="text-brand-dark bg-transparent font-medium"
          :class="{ 'text-gray-500': isLoading }"
          :title="$t('cart.removeArticle')"
          @click="removeCartItem"
        >
          <span class="sr-only">{{ $t('cart.removeArticle') }}</span>
          <i
            aria-hidden
            class="icon i-custom-svg:trash text-grey block text-xl"
          />
        </button>
      </div>
    </div>
  </div>
</template>
